import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    fetchFirmAdyavadhik(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/firm-adyavadhik`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createFirmAdyavadhik(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/firm-adyavadhik/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeFirmAdyavadhik(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/firm-adyavadhik`, params)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showFirmAdyavadhik(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/firm-adyavadhik/${params.uid}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editFirmAdyavadhik(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/firm-adyavadhik/${params.uid}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
