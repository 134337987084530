import axios from "axios";

const state = {}
const mutations = {}
const getters = {}

const actions = {
    getTrainees(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/trainee`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createTrainee(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/trainee/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeTrainee(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/trainee`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showTrainee(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/trainee/${data.trainee_id}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editTrainee(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/trainee/${data}/edit`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateTrainee(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/trainee/${data.trainee_id}?_method=PUT`, data.formData)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteTrainee(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/trainee/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    traineeReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/trainee-report`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateTraineeCertificate(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/trainee-certificate/${data.training_id}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }
}
export default {
    state,
    mutations,
    actions,
    getters
}
