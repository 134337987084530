import axios from "axios";

const state = {}
const mutations = {}
const getters = {}

const actions = {
    getHonors(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/honor`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createHonor(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/honor/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeHonor(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/honor`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showHonor(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/honor/${data.honor_id}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editHonor(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/honor/${params.honor_id}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateHonor(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/honor/${data.honor_id}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteHonor(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/honor/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    reportHonor(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/honor-report`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateStatusHonor(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/honor-status/${data.id}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    resendForReviewHonor(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/honor-resend/${data.id}/review`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
