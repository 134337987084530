import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    fetchFirm(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/firm/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }, createFirm(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/firm/create`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }, showFirm(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/firm/${params.uid}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }, fetchFirmHistory(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/firm/history/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }, fetchFirmName(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/get-firm-name`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }, fetchFirmIndexReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/firm-report`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }, updateFirmStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.patch(`/firm/${data.uid}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state, mutations, actions, getters
}
