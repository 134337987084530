import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    fetchPrivateFirms(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/private-firm`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createPrivateFirm(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/private-firm/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storePrivateFirm(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/private-firm`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showPrivateFirm(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/private-firm/${params.uid}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editPrivateFirm(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/private-firm/${data}/edit`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updatePrivateFirm(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/private-firm/${data.uid}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deletePrivateFirm(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/private-firm/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
