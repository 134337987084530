import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getProgramProgress(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/program-progress`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createProgramProgress(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/program-progress/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeProgramProgress(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/program-progress`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showProgramProgress(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/program-progress/${params.id}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editProgramProgressByUid(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/program-progress/${params.id}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateProgramProgress(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/program-progress/${data.id}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteProgramProgress(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/program-progress/${data.id}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getProgramProgressByType(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/program-progress-type`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },

    arrangeProgramProgressByType(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/program-progress-arrange`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }

}
export default {
    state,
    mutations,
    actions,
    getters
}
