import axios from "axios";

const state = {}
const mutations = {}
const getters = {}

const actions = {
    getSubsidies(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/subsidy`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createSubsidy(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/subsidy/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeSubsidy(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/subsidy`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showSubsidy(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/subsidy/${data.id}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editSubsidy(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/subsidy/${params.subsidy_id}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateSubsidy(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/subsidy/${data.subsidy_id}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteSubsidy(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/subsidy/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    reportSubsidy(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/subsidy-report`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateStatusSubsidy(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/subsidy-status/${data.id}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    resendForReviewSubsidy(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/subsidy-resend/${data.id}/review`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
