import axios from "axios";

const state = {
    trainingStartDate: null,
}
const mutations = {
    setTrainingStartDate(state, payload) {
        state.trainingStartDate = payload
    }
}
const getters = {
    trainingStartDate: state => {
        return state.trainingStartDate
    }
}

const actions = {
    getTrainings(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/training`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getProgramTrainings(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/program-training`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createTraining(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/training/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeTraining(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/training`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showTraining(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/training/${params.uid}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editTraining(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/training/${params.uid}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateTraining(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/training/${data.uid}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteTraining(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/training/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    trainingReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/training-report`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getTrainingExportView(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/training-export`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getTrainingOffice(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/training-office`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateStatusTraining(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/training-status/${data.uid}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    resendForReviewTraining(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/training-resend/${data.slug}/review`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getTrainingInstructor(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/training-instructor`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateTrainingAllowance(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/training-allowance/${data.training_id}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateTrainingCertificate(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/training-certificate/${data.training_id}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateTrainingTraineeAllowance(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/training-trainee-allowance/${data.training_id}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
