import axios from "axios";

const state = {
    selectedExperienceIndex: undefined,
    selectedEducationIndex: undefined,
    selectedExperience: undefined,
    selectedEducation: undefined,
}
const mutations = {
    selectedExperienceIndex(state, value) {
        state.selectedExperienceIndex = value
    },
    selectedEducationIndex(state, value) {
        state.selectedEducationIndex = value
    },
    selectedExperience(state, value) {
        state.selectedExperience = value
    },
    selectedEducation(state, value) {
        state.selectedEducation = value
    }
}
const getters = {
    selectedExperienceIndex: state => state.selectedExperienceIndex,
    selectedEducationIndex: state => state.selectedEducationIndex,
    selectedExperience: state => state.selectedExperience,
    selectedEducation: state => state.selectedEducation,
}

const actions = {
    getTrainers(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/trainer`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createTrainer(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/trainer/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeTrainer(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/trainer`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showTrainer(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/trainer/${data.trainer_id}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editTrainer(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/trainer/${data}/edit`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateTrainer(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/trainer/${data.trainer_id}?_method=PUT`, data.formData)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteTrainer(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/trainer/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    TrainerReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/trainer-report`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
