import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getTrainingReportIndex(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get('/training-report-index', {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getTrainingReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get('/training-report-report', {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getTrainingInstructorReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get('/training-instructor-report', {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getTrainerListReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get('/trainer-report', {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getTrainingTraineeReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get('/training-trainee-report', {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
//    Subsidy
    getSubsidyReportIndex(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get('/subsidy-report-index', {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getSubsidyReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get('/subsidy-report-report', {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getSubsidyGrantReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get('/subsidy-grant-report', {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    //    Honor
    getHonorReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get('/honor-report-report', {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getHonorMemberReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get('/honor-member-report', {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    //    Firm
    getFirmIndustryPreList(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get('/firm-industry-pre-list', {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getFirmIndustryReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get('/firm-industry-report', {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getFirmCommercialPreList(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get('/firm-commercial-pre-list', {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getFirmCommercialReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get('/firm-commercial-report', {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
