import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    createFirmTransfer(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/firm-transfer/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeFirmTransfer(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/firm-transfer`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showFirmTransfer(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/firm-transfer/${params.uid}`, params)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
