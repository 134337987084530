import axios from "axios";

const state = {}
const mutations = {}
const getters = {}

const actions = {
    getHonorMembers(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/honor-member`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createHonorMember(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/honor-member/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeHonorMember(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/honor-member`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showHonorMember(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/honor-member/${params.member_id}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editHonorMember(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/honor-member/${params.honor_member_id}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateHonorMember(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/honor-member/${data.member_id}?_method=PUT`, data.formData)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteHonorMember(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/honor-member/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }
}
export default {
    state,
    mutations,
    actions,
    getters
}
