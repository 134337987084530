import axios from "axios";

const state = {}
const mutations = {}
const getters = {}

const actions = {
    getTraineeStatuses(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/trainee-status`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createTraineeStatus(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/trainee-status/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeTraineeStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/trainee-status`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showTraineeStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/trainee-status/${data.slug}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editTraineeStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/trainee-status/${data}/edit`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateTraineeStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/trainee-status/${data.status_id}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteTraineeStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/trainee-status/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }
}
export default {
    state,
    mutations,
    actions,
    getters
}
