import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getIndustryAdministrations(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/industry-administration`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createIndustryAdministration(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/industry-administration/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeIndustryAdministration(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/industry-administration`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showIndustryAdministration(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/industry-administration/${params.uid}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editIndustryAdministrationByUid(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/industry-administration/${params.uid}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateIndustryAdministration(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/industry-administration/${data.uid}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteIndustryAdministration(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/industry-administration/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getIndustryAdministrationExportView(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/industry-administration-export`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateIndustryAdministrationStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/industry-administration-status/${data.uid}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    resendForReviewIndustryAdministration(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/industry-administration-resend/${data.uid}/review`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    industryAdministrationReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/industry-administration-report`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
