import axios from "axios";

const state = {}
const mutations = {}
const getters = {}

const actions = {
    getTraineeMachines(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/trainee-machine`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createTraineeMachine(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/trainee-machine/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeTraineeMachine(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/trainee-machine`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showTraineeMachine(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/trainee-machine/${data.slug}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editTraineeMachine(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/trainee-machine/${data}/edit`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateTraineeMachine(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/trainee-machine/${data.machine_id}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteTraineeMachine(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/trainee-machine/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }
}
export default {
    state,
    mutations,
    actions,
    getters
}
