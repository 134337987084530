import axios from "axios";

const state = {}
const mutations = {}
const getters = {}

const actions = {
    getTraineeAttendances(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`attendance`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createTraineeAttendance(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`attendance/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeTraineeAttendance(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`attendance`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showTraineeAttendance(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`attendance/${data.training_id}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editTraineeAttendance(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`attendance/${data}/edit`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateTraineeAttendance(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`attendance/${data.training_id}?_method=PUT`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteTraineeAttendance(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`attendance/${data.training_id}`, {params: data})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }
}
export default {
    state,
    mutations,
    actions,
    getters
}
