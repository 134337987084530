import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    fetchSajhedariFirms(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/sajhedari-firm`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createSajhedariFirm(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/sajhedari-firm/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeSajhedariFirm(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/sajhedari-firm`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showSajhedariFirm(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/sajhedari-firm/${params.uid}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editSajhedariFirm(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/sajhedari-firm/${data}/edit`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateSajhedariFirm(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/sajhedari-firm/${data.uid}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteSajhedariFirm(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/sajhedari-firm/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
