const Login = () => import (/* webpackChunkName: "common-group" */ './views/account/Login.vue');
const Dashboard = () => import (/* webpackChunkName: "common-group" */'./views/Dashboard/Dashboard.vue');
const Profile = () => import (/* webpackChunkName: "common-group" */'./views/Profile/Profile.vue');
const Security = () => import (/* webpackChunkName: "common-group" */'./views/Profile/Security.vue');

const TrainingIndex = () => import (/* webpackChunkName: "training-group" */'./views/Training/TrainingIndex.vue');
const TrainingForm = () => import (/* webpackChunkName: "training-group" */'./views/Training/TrainingForm.vue');
const TrainingView = () => import (/* webpackChunkName: "training-group" */'./views/Training/TrainingView.vue');
const TrainingReport = () => import (/* webpackChunkName: "training-group" */'./views/Training/TrainingReport.vue');
const TrainingTraineeSelect = () => import (/* webpackChunkName: "training-group" */'./views/Training/TrainingTraineeSelect.vue');
const TrainingTraineeForm = () => import (/* webpackChunkName: "training-group" */'./views/Training/TrainingTraineeForm.vue');
const TrainingTraineeExistingForm = () => import (/* webpackChunkName: "training-group" */'./views/Training/TrainingTraineeExistingForm.vue');
const TrainingInstructor = () => import (/* webpackChunkName: "training-group" */'./views/Training/TrainingInstructor.vue');
const TrainingTraineeReportView = () => import (/* webpackChunkName: "training-group" */'./views/Training/TrainingTraineeReportView.vue');

//Subsidy
const SubsidyIndex = () => import (/* webpackChunkName: "subsidy-group" */'./views/Subsidy/SubsidyIndex.vue');
const SubsidyForm = () => import (/* webpackChunkName: "subsidy-group" */'./views/Subsidy/SubsidyForm.vue');
const SubsidyView = () => import (/* webpackChunkName: "subsidy-group" */'./views/Subsidy/SubsidyView.vue');
const SubsidyReport = () => import (/* webpackChunkName: "subsidy-group" */'./views/Subsidy/SubsidyReport.vue');
const SubsidyGrantForm = () => import (/* webpackChunkName: "subsidy-group" */'./views/Subsidy/SubsidyGrant/SubsidyGrantForm.vue');
const SubsidyGrantView = () => import (/* webpackChunkName: "subsidy-group" */'./views/Subsidy/SubsidyGrant/SubsidyGrantView.vue');

//Honor
const HonorIndex = () => import (/* webpackChunkName: "honor-group" */'./views/Honor/HonorIndex.vue');
const HonorForm = () => import (/* webpackChunkName: "honor-group" */'./views/Honor/HonorForm.vue');
const HonorView = () => import (/* webpackChunkName: "honor-group" */'./views/Honor/HonorView.vue');
const HonorReport = () => import (/* webpackChunkName: "honor-group" */'./views/Honor/HonorReport.vue');
const HonorMemberForm = () => import (/* webpackChunkName: "honor-group" */'./views/Honor/HonorMember/HonorMemberForm.vue');
const HonorMemberView = () => import (/* webpackChunkName: "honor-group" */'./views/Honor/HonorMember/HonorMemberView.vue');

const PeopleStatusIndex = () => import (/* webpackChunkName: "trainee-group" */'./views/Trainee/PeopleService/PeopleServiceIndex.vue');
const PeopleStatusForm = () => import (/* webpackChunkName: "trainee-group" */'./views/Trainee/PeopleService/PeopleServiceForm');
const TraineeIndex = () => import (/* webpackChunkName: "trainee-group" */'./views/Trainee/TraineeIndex.vue');
const TraineeForm = () => import (/* webpackChunkName: "trainee-group" */'./views/Trainee/TraineeForm.vue');
const TraineeView = () => import (/* webpackChunkName: "trainee-group" */'./views/Trainee/TraineeView.vue');
const TraineeReport = () => import (/* webpackChunkName: "trainee-group" */'./views/Trainee/TraineeReport.vue');
const TraineeMachineIndex = () => import (/* webpackChunkName: "trainee-group" */'./views/Trainee/TraineeMachine/TraineeMachineIndex.vue');
const TraineeMachineForm = () => import (/* webpackChunkName: "trainee-group" */'./views/Trainee/TraineeMachine/TraineeMachineForm.vue');
const TraineeMachineFormEdit = () => import (/* webpackChunkName: "trainee-group" */'./views/Trainee/TraineeMachine/TraineeMachineFormEdit.vue');
const TraineeServiceIndex = () => import (/* webpackChunkName: "trainee-group" */'./views/Trainee/TraineeService/TraineeServiceIndex.vue');
const TraineeServiceForm = () => import (/* webpackChunkName: "trainee-group" */'./views/Trainee/TraineeService/TraineeServiceForm.vue');
const TraineeStatusIndex = () => import (/* webpackChunkName: "trainee-group" */'./views/Trainee/TraineeStatus/TraineeStatusIndex.vue');
const TraineeStatusForm = () => import (/* webpackChunkName: "trainee-group" */'./views/Trainee/TraineeStatus/TraineeStatusForm.vue');

const UserIndex = () => import (/* webpackChunkName: "user-group" */'./views/Users/UserIndex.vue');
const UserForm = () => import (/* webpackChunkName: "user-group" */'./views/Users/UserForm.vue');
const RoleIndex = () => import (/* webpackChunkName: "user-group" */'./views/Role/RoleIndex.vue');
const RoleForm = () => import (/* webpackChunkName: "user-group" */'./views/Role/RoleForm.vue');
// const UserView = () => import (/* webpackChunkName: "program-group" */'./views/Program/ProgramView.vue');

const TrainerIndex = () => import (/* webpackChunkName: "trainer-group" */'./views/Trainer/TrainerIndex.vue');
const TrainerForm = () => import (/* webpackChunkName: "trainer-group" */'./views/Trainer/TrainerForm.vue');
const TrainerView = () => import (/* webpackChunkName: "trainer-group" */'./views/Trainer/TrainerView.vue');
const TrainerReport = () => import (/* webpackChunkName: "trainer-group" */'./views/Trainer/TrainerReport.vue');

const IndustryRegistrationIndex = () => import (/* webpackChunkName: "group-industry" */ './views/Industry/IndustryRegistration/IndustryRegistrationIndex.vue');
const IndustryRegistrationForm = () => import (/* webpackChunkName: "group-industry" */ './views/Industry/IndustryRegistration/IndustryRegistrationForm.vue');
const IndustryRegistrationView = () => import (/* webpackChunkName: "group-industry" */ './views/Industry/IndustryRegistration/IndustryRegistrationView.vue');
const IndustryRegistrationReport = () => import (/* webpackChunkName: "group-industry" */ './views/Industry/IndustryRegistration/IndustryRegistrationReport.vue');
const IndustryAdministrationIndex = () => import (/* webpackChunkName: "group-industry" */ './views/Industry/IndustryAdministration/IndustryAdministrationIndex.vue');
const IndustryAdministrationForm = () => import (/* webpackChunkName: "group-industry" */ './views/Industry/IndustryAdministration/IndustryAdministrationForm.vue');
const IndustryAdministrationView = () => import (/* webpackChunkName: "group-industry" */ './views/Industry/IndustryAdministration/IndustryAdministrationView.vue');
const IndustryAdministrationReport = () => import (/* webpackChunkName: "group-industry" */ './views/Industry/IndustryAdministration/IndustryAdministrationReport.vue');
const CommercialRegistrationIndex = () => import (/* webpackChunkName: "group-industry" */ './views/Commercial/CommercialRegistration/CommercialRegistrationIndex');
const CommercialRegistrationForm = () => import (/* webpackChunkName: "group-industry" */ './views/Commercial/CommercialRegistration/CommercialRegistrationForm.vue');
const CommercialRegistrationView = () => import (/* webpackChunkName: "group-industry" */ './views/Commercial/CommercialRegistration/CommercialRegistrationView.vue');
const CommercialRegistrationReport = () => import (/* webpackChunkName: "group-industry" */ './views/Commercial/CommercialRegistration/CommercialRegistrationReport.vue');

const ProgramProgressIndex = () => import (/* webpackChunkName: "program-progress-industry" */ './views/ProgramProgress/ProgramProgressIndex');
const ProgramProgressForm = () => import (/* webpackChunkName: "program-progress-industry" */ './views/ProgramProgress/ProgramProgressForm.vue');
const ProgramProgressView = () => import (/* webpackChunkName: "program-progress-industry" */ './views/ProgramProgress/ProgramProgressView.vue');
const ProgramProgressArrange = () => import (/* webpackChunkName: "program-progress-industry" */ './views/ProgramProgress/ProgramProgressArrange.vue');
const MonthlyProgressIndex = () => import (/* webpackChunkName: "program-progress-industry" */ './views/MonthlyProgress/MonthlyProgressIndex');
const MonthlyProgressForm = () => import (/* webpackChunkName: "program-progress-industry" */ './views/MonthlyProgress/MonthlyProgressForm.vue');
const MonthlyProgressView = () => import (/* webpackChunkName: "program-progress-industry" */ './views/MonthlyProgress/MonthlyProgressView.vue');
const MonthlyProgressReport = () => import (/* webpackChunkName: "program-progress-industry" */ './views/MonthlyProgress/MonthlyProgressReport.vue');

const ReportIndex = () => import (/* webpackChunkName: "report-group" */ './views/Report/ReportIndex.vue');
const ReportTraining = () => import (/* webpackChunkName: "report-group" */ './views/Report/Training/TrainingAll.vue');
const ReportTrainingInstructor = () => import (/* webpackChunkName: "report-group" */ './views/Report/Training/TrainingInstructor.vue');
const ReportTrainingTrainee = () => import (/* webpackChunkName: "report-group" */ './views/Report/Training/TrainingTrainee.vue');
const ReportTrainerList = () => import (/* webpackChunkName: "report-group" */'./views/Report/Training/TrainerList.vue');
const ReportSubsidy = () => import (/* webpackChunkName: "report-group" */ './views/Report/Subsidy/SubsidyAll.vue');
const ReportSubsidyGrant = () => import (/* webpackChunkName: "report-group" */ './views/Report/Subsidy/SubsidyGrant.vue');
const ReportHonor = () => import (/* webpackChunkName: "report-group" */ './views/Report/Honor/HonorAll.vue');
const ReportHonorMember = () => import (/* webpackChunkName: "report-group" */ './views/Report/Honor/HonorMember.vue');
const ReportFirmIndustry = () => import (/* webpackChunkName: "report-group" */ './views/Report/Firm/FirmIndustry.vue');
const ReportFirmCommercial = () => import (/* webpackChunkName: "report-group" */ './views/Report/Firm/FirmCommercial.vue');

//search
const SearchIndex = () => import (/* webpackChunkName: "search-group" */ './views/Search/SearchIndex.vue');
const AttendanceIndex = () => import (/* webpackChunkName: "search-group" */ './views/Training/Attendance/AttendanceIndex.vue');
const AttendanceForm = () => import (/* webpackChunkName: "search-group" */ './views/Training/Attendance/AttendanceForm.vue');
const AttendanceReport = () => import (/* webpackChunkName: "search-group" */ './views/Training/Attendance/AttendanceReport.vue');

const CapitalIndex = () => import (/* webpackChunkName: "setting-group" */ './views/Settings/Capital/CapitalIndex.vue');
const CapitalForm = () => import (/* webpackChunkName: "setting-group" */ './views/Settings/Capital/CapitalForm.vue');
const PurposeIndex = () => import (/* webpackChunkName: "setting-group" */ './views/Settings/Purpose/PurposeIndex.vue');
const PurposeForm = () => import (/* webpackChunkName: "setting-group" */ './views/Settings/Purpose/PurposeForm.vue');
const OptionIndex = () => import (/* webpackChunkName: "setting-group" */ './views/Settings/Options/OptionIndex.vue');
const OptionForm = () => import (/* webpackChunkName: "setting-group" */ './views/Settings/Options/OptionForm.vue');

//SajhedariFirm
const SajhedariFirmIndex = () => import (/* webpackChunkName: "sajhedari-firm-group" */ './views/Firm/SajhedariFirm/SajhedariFirmIndex.vue');
const SajhedariFirmForm = () => import (/* webpackChunkName: "sajhedari-firm-group" */ './views/Firm/SajhedariFirm/SajhedariFirmForm.vue');
const SajhedariFirmHistory = () => import (/* webpackChunkName: "sajhedari-firm-group" */ './views/Firm/SajhedariFirm/SajhedariFirmHistory.vue');

//PrivateFirm
const PrivateFirmIndex = () => import (/* webpackChunkName: "private-firm-group" */ './views/Firm/PrivateFirm/PrivateFirmIndex.vue');
const PrivateFirmForm = () => import (/* webpackChunkName: "private-firm-group" */ './views/Firm/PrivateFirm/PrivateFirmForm.vue');
const PrivateFirmHistory = () => import (/* webpackChunkName: "private-firm-group" */ './views/Firm/PrivateFirm/PrivateFirmHistory.vue');

const FirmRenewalCertificate = () => import (/* webpackChunkName: "firm-group" */ './views/Firm/Certificate/FirmRenewalCertificate.vue');
const FirmKharejiCertificate = () => import (/* webpackChunkName: "firm-group" */ './views/Firm/Certificate/FirmKharejiCertificate.vue');
const FirmTransferCertificate = () => import (/* webpackChunkName: "firm-group" */ './views/Firm/Certificate/FirmTransferCertificate.vue');
const FirmSamsodhanCertificate = () => import (/* webpackChunkName: "firm-group" */ './views/Firm/Certificate/FirmSamsodhanCertificate.vue');
const FirmCertificate = () => import (/* webpackChunkName: "firm-group" */ './views/Firm/Certificate/FirmCertificate.vue');
const FirmRegisterCertificate = () => import (/* webpackChunkName: "firm-group" */ './views/Firm/Certificate/FirmRegisterCertificate.vue');
const FirmBranchCertificate = () => import (/* webpackChunkName: "firm-group" */ './views/Firm/Certificate/FirmBranchCertificate.vue');
const FirmBranchLetter = () => import (/* webpackChunkName: "firm-group" */ './views/Firm/Certificate/FirmBranchLetter.vue');

//RegisterFirm
const RegisterFirmIndex = () => import (/* webpackChunkName: "register-firm-group" */ './views/Firm/RegisterFirm/FirmRegisterIndex.vue');
const RegisterFirmForm = () => import (/* webpackChunkName: "register-firm-group" */ './views/Firm/RegisterFirm/FirmRegisterForm.vue');
const RegisterFirmHistory = () => import (/* webpackChunkName: "register-firm-group" */ './views/Firm/RegisterFirm/FirmRegisterHistory.vue');
const RegisterFirmSamdodhan = () => import (/* webpackChunkName: "register-firm-group" */ './views/Firm/RegisterFirm/FirmRegisterCorrection.vue');
const RegisterFirmAdyavadhik = () => import (/* webpackChunkName: "register-firm-group" */ './views/Firm/RegisterFirm/FirmRegisterAdyavadhikForm.vue');
const RegisterFirmAdyavadhikCertificate = () => import (/* webpackChunkName: "register-firm-group" */ './views/Firm/Certificate/FirmAdyavadhikCertificate.vue');
const RegisterFirmCertificate = () => import (/* webpackChunkName: "register-firm-group" */ './views/Firm/Certificate/RegisterFirmCertificate.vue');
const RegisterFirmRegisterCertificate = () => import (/* webpackChunkName: "register-firm-group" */ './views/Firm/Certificate/RegisterFirmRegisterCertificate.vue');
const RegisterFirmSamsodhanCertificate = () => import (/* webpackChunkName: "register-firm-group" */ './views/Firm/Certificate/FirmRegisterSamsodhanCertificate.vue');
const RegisterFirmDateExtensionCertificate = () => import (/* webpackChunkName: "register-firm-group" */ './views/Firm/Certificate/FirmDateExtendCertificate.vue');

const routes = [
    {
        path: '/',
        name: 'login',
        component: Login,
        meta: {
            requiresVisitor: true,
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            title: 'Dashboard', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            title: 'Profile', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/security',
        name: 'security',
        component: Security,
        meta: {
            title: 'Security', rule: true,
            requiresAuth: true,
        }
    },

    //Training
    {
        path: '/trainings',
        name: 'training-index',
        component: TrainingIndex,
        meta: {
            title: 'Trainings', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/trainings/create',
        name: 'training-create',
        component: TrainingForm,
        meta: {
            title: 'Create Training', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/trainings/:uid/show',
        name: 'training-view',
        params: true,
        component: TrainingView,
        meta: {
            title: 'View Training', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/trainings/:uid/edit',
        name: 'training-edit',
        params: true,
        component: TrainingForm,
        meta: {
            title: 'Edit Training', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/trainings/report',
        name: 'training-report',
        params: true,
        component: TrainingReport,
        meta: {
            title: 'Report Training', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/trainings/:uid/trainee/select',
        name: 'training-trainee-select',
        params: true,
        component: TrainingTraineeSelect,
        meta: {
            title: 'Select Training Trainee', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/trainings/:uid/trainee/create',
        name: 'training-trainee-create',
        params: true,
        component: TrainingTraineeForm,
        meta: {
            title: 'Create Training Trainee', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/trainings/:uid/trainee/:id/edit',
        name: 'training-trainee-edit',
        params: true,
        component: TrainingTraineeForm,
        meta: {
            title: 'Edit Training Trainee', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/trainings-instructor',
        name: 'training-instructor',
        params: true,
        component: TrainingInstructor,
        meta: {
            title: 'Training Instructors', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/trainings-trainee-existing',
        name: 'training-trainee-existing',
        params: true,
        component: TrainingTraineeExistingForm,
        meta: {
            title: 'Create Training Trainee', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/trainings/:uid/show-report',
        name: 'training-trainee-report-view',
        params: true,
        component: TrainingTraineeReportView,
        meta: {
            title: 'Report Training', rule: true,
            requiresAuth: true,
        }
    },

    // People Status
    {
        path: '/people-status',
        name: 'people-status-index',
        params: true,
        component: PeopleStatusIndex,
        meta: {
            title: 'Create People Status Index', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/people-status/create',
        name: 'people-status-create',
        params: true,
        component: PeopleStatusForm,
        meta: {
            title: 'Create People Status Form', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/people-status/:training_id/edit',
        name: 'people-status-edit',
        params: true,
        component: PeopleStatusForm,
        meta: {
            title: 'Edit People Status Form', rule: true,
            requiresAuth: true,
        }
    },


    //Trainer
    {
        path: '/trainers',
        name: 'trainer-index',
        component: TrainerIndex,
        meta: {
            title: 'Trainers', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/trainers/create',
        name: 'trainer-create',
        component: TrainerForm,
        meta: {
            title: 'Create Trainer', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/trainers/:trainer_id/edit',
        name: 'trainer-edit',
        params: true,
        component: TrainerForm,
        meta: {
            title: 'Edit Trainer', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/trainers/:trainer_id/show',
        name: 'trainer-view',
        params: true,
        component: TrainerView,
        meta: {
            title: 'View Trainer', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/trainers/report',
        name: 'trainer-report',
        params: true,
        component: TrainerReport,
        meta: {
            title: 'Report Trainer', rule: true,
            requiresAuth: true,
        }
    },


    //Trainee
    {
        path: '/trainees',
        name: 'trainee-index',
        component: TraineeIndex,
        meta: {
            title: 'Trainees', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/trainees/create',
        name: 'trainee-create',
        component: TraineeForm,
        meta: {
            title: 'Create Trainee', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/trainees/:trainee_id/show',
        name: 'trainee-view',
        params: true,
        component: TraineeView,
        meta: {
            title: 'View Trainee', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/trainees/report',
        name: 'trainee-report',
        params: true,
        component: TraineeReport,
        meta: {
            title: 'Report Trainee', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/trainees/:trainee_id/edit',
        name: 'trainee-edit',
        params: true,
        component: TraineeForm,
        meta: {
            title: 'Edit Trainee', rule: true,
            requiresAuth: true,
        }
    },

    //Trainee Machine
    {
        path: '/trainees/:trainee_id/machine',
        name: 'trainee-machine-index',
        params: true,
        component: TraineeMachineIndex,
        meta: {
            title: 'Trainee Machine', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/trainees/:trainee_id/machine/create',
        name: 'trainee-machine-create',
        component: TraineeMachineForm,
        meta: {
            title: 'Create Trainee Machine', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/trainees/:trainee_id/machine/:machine_id/edit',
        name: 'trainee-machine-edit',
        params: true,
        component: TraineeMachineFormEdit,
        meta: {
            title: 'Edit Trainee Machine', rule: true,
            requiresAuth: true,
        }
    },

    //Trainee Status
    {
        path: '/trainees/:trainee_id/status',
        name: 'trainee-status-index',
        params: true,
        component: TraineeStatusIndex,
        meta: {
            title: 'Trainee Service', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/trainees/:trainee_id/status/create',
        name: 'trainee-status-create',
        component: TraineeStatusForm,
        meta: {
            title: 'Create Trainee Status', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/trainees/:trainee_id/status/:status_id/edit',
        name: 'trainee-status-edit',
        params: true,
        component: TraineeStatusForm,
        meta: {
            title: 'Edit Trainee Status', rule: true,
            requiresAuth: true,
        }
    },

    //Trainee Service
    {
        path: '/trainees/:trainee_id/service',
        name: 'trainee-service-index',
        params: true,
        component: TraineeServiceIndex,
        meta: {
            title: 'Trainee Service', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/trainees/:trainee_id/service/create',
        name: 'trainee-service-create',
        component: TraineeServiceForm,
        meta: {
            title: 'Create Trainee Service', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/trainees/:trainee_id/service/:service_id/edit',
        name: 'trainee-service-edit',
        params: true,
        component: TraineeServiceForm,
        meta: {
            title: 'Edit Trainee Service', rule: true,
            requiresAuth: true,
        }
    },


    //Subsidy
    {
        path: '/subsidies',
        name: 'subsidy-index',
        params: true,
        component: SubsidyIndex,
        meta: {
            title: 'Subsidy Index', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/subsidies/create',
        name: 'subsidy-create',
        component: SubsidyForm,
        meta: {
            title: 'Create Subsidy', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/subsidies/:subsidy_id/edit',
        name: 'subsidy-edit',
        params: true,
        component: SubsidyForm,
        meta: {
            title: 'Edit Subsidy', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/subsidies/:subsidy_id/view',
        name: 'subsidy-view',
        params: true,
        component: SubsidyView,
        meta: {
            title: 'View Subsidy', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/subsidies/report',
        name: 'subsidy-report',
        params: true,
        component: SubsidyReport,
        meta: {
            title: 'Report Subsidy', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/subsidies/:subsidy_id/grant/create',
        name: 'subsidy-grant-form',
        params: true,
        component: SubsidyGrantForm,
        meta: {
            title: 'Subsidy Grant Form', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/subsidies/:subsidy_id/grant/:organisation_id/edit',
        name: 'subsidy-grant-edit',
        params: true,
        component: SubsidyGrantForm,
        meta: {
            title: 'Edit Subsidy Grant Form', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/subsidies/:subsidy_id/grant/:organisation_id/view',
        name: 'subsidy-grant-view',
        params: true,
        component: SubsidyGrantView,
        meta: {
            title: 'View Subsidy Grant Form', rule: true,
            requiresAuth: true,
        }
    },
    //Users Management
    {
        path: '/users',
        name: 'user-index',
        params: true,
        component: UserIndex,
        meta: {
            title: 'Users', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/users/create',
        name: 'user-create',
        params: true,
        component: UserForm,
        meta: {
            title: 'Create User', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/users/:username/edit',
        name: 'user-edit',
        params: true,
        component: UserForm,
        meta: {
            title: 'Edit User', rule: true,
            requiresAuth: true,
        }
    },

    //Role Management
    {
        path: '/roles',
        name: 'role-index',
        params: true,
        component: RoleIndex,
        meta: {
            title: 'Roles', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/roles/create',
        name: 'role-create',
        params: true,
        component: RoleForm,
        meta: {
            title: 'Create Role', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/roles/:id/edit',
        name: 'role-edit',
        params: true,
        component: RoleForm,
        meta: {
            title: 'Edit Role', rule: true,
            requiresAuth: true,
        }
    },

    //Industry Management
    {
        path: '/industry-registrations',
        name: 'industry-registration-index',
        component: IndustryRegistrationIndex,
        meta: {
            title: 'Industry Registrations', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/industry-registrations/create',
        name: 'industry-registration-create',
        component: IndustryRegistrationForm,
        meta: {
            title: 'Create Industry Registration', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/industry-registrations/:uid/edit',
        name: 'industry-registration-edit',
        component: IndustryRegistrationForm,
        params: true,
        meta: {
            title: 'Edit Industry Registration', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/industry-registrations/:uid/view',
        name: 'industry-registration-view',
        params: true,
        component: IndustryRegistrationView,
        meta: {
            title: 'View Industry Registration', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/industry-registrations/report',
        name: 'industry-registration-report',
        component: IndustryRegistrationReport,
        meta: {
            title: 'Report Industry Registration', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/industry-administrations',
        name: 'industry-administration-index',
        component: IndustryAdministrationIndex,
        meta: {
            title: 'Industry Administrations', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/industry-administrations/create',
        name: 'industry-administration-create',
        component: IndustryAdministrationForm,
        meta: {
            title: 'Create Industry Administration', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/industry-administrations/:uid/edit',
        name: 'industry-administration-edit',
        component: IndustryAdministrationForm,
        params: true,
        meta: {
            title: 'Edit Industry Administration', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/industry-administrations/:uid/view',
        name: 'industry-administration-view',
        component: IndustryAdministrationView,
        params: true,
        meta: {
            title: 'View Industry Administration', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/industry-administrations/report',
        name: 'industry-administration-report',
        component: IndustryAdministrationReport,
        meta: {
            title: 'Report Industry Administration', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/commercial-registrations',
        name: 'commercial-registration-index',
        component: CommercialRegistrationIndex,
        meta: {
            title: 'Commercial Registrations', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/commercial-registrations/create',
        name: 'commercial-registration-create',
        component: CommercialRegistrationForm,
        meta: {
            title: 'Create Commercial Registration', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/commercial-registrations/:uid/edit',
        name: 'commercial-registration-edit',
        params: true,
        component: CommercialRegistrationForm,
        meta: {
            title: 'Edit Commercial Registration', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/commercial-registrations/:uid/view',
        name: 'commercial-registration-view',
        component: CommercialRegistrationView,
        meta: {
            title: 'View Commercial Registration', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/commercial-registrations/report',
        name: 'commercial-registration-report',
        component: CommercialRegistrationReport,
        meta: {
            title: 'Report Commercial Registration', rule: true,
            requiresAuth: true,
        }
    },

    //Monthly Program Progress
    {
        path: '/monthly-progress',
        name: 'monthly-progress-index',
        component: MonthlyProgressIndex,
        meta: {
            title: 'Monthly Programs', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/monthly-progress/create',
        name: 'monthly-progress-create',
        component: MonthlyProgressForm,
        meta: {
            title: 'Create Monthly Program', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/monthly-progress/:uid/edit',
        name: 'monthly-progress-edit',
        params: true,
        component: MonthlyProgressForm,
        meta: {
            title: 'Edit Monthly Program', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/monthly-progress/:uid/view',
        name: 'monthly-progress-view',
        component: MonthlyProgressView,
        meta: {
            title: 'View Monthly Program', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/monthly-progress/report',
        name: 'monthly-progress-report',
        component: MonthlyProgressReport,
        meta: {
            title: 'Report Monthly Program', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/program-progress',
        name: 'program-progress-index',
        component: ProgramProgressIndex,
        meta: {
            title: 'Programs', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/program-progress/create',
        name: 'program-progress-create',
        component: ProgramProgressForm,
        meta: {
            title: 'Create Program', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/program-progress/:id/edit',
        name: 'program-progress-edit',
        params: true,
        component: ProgramProgressForm,
        meta: {
            title: 'Edit Program', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/program-progress/:id/view',
        name: 'program-progress-view',
        component: ProgramProgressView,
        meta: {
            title: 'View Program', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/program-progress/arrange',
        name: 'program-progress-arrange',
        component: ProgramProgressArrange,
        meta: {
            title: 'Arrange Program', rule: true,
            requiresAuth: true,
        }
    },

    //Honor
    {
        path: '/honors',
        name: 'honor-index',
        params: true,
        component: HonorIndex,
        meta: {
            title: 'Honor Index', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/honors/create',
        name: 'honor-create',
        component: HonorForm,
        meta: {
            title: 'Create Honor', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/honors/:honor_id/edit',
        name: 'honor-edit',
        params: true,
        component: HonorForm,
        meta: {
            title: 'Edit Honor', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/honors/:honor_id/view',
        name: 'honor-view',
        params: true,
        component: HonorView,
        meta: {
            title: 'View Honor', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/honors/report',
        name: 'honor-report',
        params: true,
        component: HonorReport,
        meta: {
            title: 'Report Honor', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/honors/:honor_id/member/create',
        name: 'honor-member-form',
        params: true,
        component: HonorMemberForm,
        meta: {
            title: 'Honor Member Form', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/honors/:honor_id/member/:member_id/edit',
        name: 'honor-member-edit',
        params: true,
        component: HonorMemberForm,
        meta: {
            title: 'Edit Honor Member Form', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/honors/:honor_id/member/:member_id/view',
        name: 'honor-member-view',
        params: true,
        component: HonorMemberView,
        meta: {
            title: 'View Honor Member View', rule: true,
            requiresAuth: true,
        }
    },
    //Report
    {
        path: '/reports',
        name: 'report-index',
        params: true,
        component: ReportIndex,
        meta: {
            title: 'Reports', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/report-training',
        name: 'report-training',
        params: true,
        component: ReportTraining,
        meta: {
            title: 'Reports Training', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/report-training-instructor',
        name: 'report-training-instructor',
        params: true,
        component: ReportTrainingInstructor,
        meta: {
            title: 'Reports Training Instructor', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/report-training-trainee',
        name: 'report-training-trainee',
        params: true,
        component: ReportTrainingTrainee,
        meta: {
            title: 'Reports Training Trainee', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/report-trainer-list',
        name: 'report-trainer-list',
        params: true,
        component: ReportTrainerList,
        meta: {
            title: 'Reports Trainer list', rule: true,
            requiresAuth: true,
        }
    },

    {
        path: '/report-subsidy',
        name: 'report-subsidy',
        params: true,
        component: ReportSubsidy,
        meta: {
            title: 'Reports Subsidy', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/report-subsidy-grant',
        name: 'report-subsidy-grant',
        params: true,
        component: ReportSubsidyGrant,
        meta: {
            title: 'Reports Subsidy Grant', rule: true,
            requiresAuth: true,
        }
    },

    {
        path: '/report-honor',
        name: 'report-honor',
        params: true,
        component: ReportHonor,
        meta: {
            title: 'Reports Honor', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/report-honor-member',
        name: 'report-honor-member',
        params: true,
        component: ReportHonorMember,
        meta: {
            title: 'Reports Honor Member', rule: true,
            requiresAuth: true,
        }
    },

    //search
    {
        path: '/search',
        name: 'search-index',
        params: true,
        component: SearchIndex,
        meta: {
            title: 'Search', rule: true,
        }
    },

    // Attendance of trainee
    {
        path: '/trainings/:training_id/attendance',
        name: 'attendance-index',
        params: true,
        component: AttendanceIndex,
        meta: {
            title: 'Attendance', rule: true,
        }
    },
    {
        path: '/trainings/:training_id/attendance/form',
        name: 'attendance-form',
        params: true,
        component: AttendanceForm,
        meta: {
            title: 'Attendance Form', rule: true,
        }
    },
    {
        path: '/trainings/:training_id/attendance/report',
        name: 'attendance-report',
        params: true,
        component: AttendanceReport,
        meta: {
            title: 'Attendance Report', rule: true,
        }
    },

    //SajhedariFirm
    {
        path: '/sajhedari-firms',
        name: 'sajhedari-firm-index',
        component: SajhedariFirmIndex,
        meta: {
            title: 'Sajhedari Firm', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/sajhedari-firms/create',
        name: 'sajhedari-firm-create',
        component: SajhedariFirmForm,
        meta: {
            title: 'Create Sajhedari Firm', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/sajhedari-firms/:uid/edit',
        name: 'sajhedari-firm-edit',
        component: SajhedariFirmForm,
        meta: {
            title: 'Edit Sajhedari Firm', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/sajhedari-firms/:uid/history',
        name: 'sajhedari-firm-history',
        component: SajhedariFirmHistory,
        meta: {
            title: 'Private Firm History', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/sajhedari-firms/certificate/:uid',
        name: 'sajhedari-firm-certificate',
        component: FirmCertificate,
        meta: {
            title: 'Certificate', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/sajhedari-firms/register-certificate/:uid',
        name: 'sajhedari-firm-letter-certificate',
        component: FirmRegisterCertificate,
        meta: {
            title: 'Registration Letter', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/sajhedari-firms/renewal-certificate/:uid/:firm_id',
        name: 'sajhedari-firm-renewal-certificate',
        component: FirmRenewalCertificate,
        meta: {
            title: 'Renewal Certificate', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/sajhedari-firms/samsodhan-certificate/:uid/:firm_id',
        name: 'sajhedari-firm-samsodhan-certificate',
        component: FirmSamsodhanCertificate,
        meta: {
            title: 'Samsodhan Certificate', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/sajhedari-firms/khareji-certificate/:uid/:firm_id',
        name: 'sajhedari-firm-khareji-certificate',
        component: FirmKharejiCertificate,
        meta: {
            title: 'Khareji Certificate', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/sajhedari-firms/transfer-certificate/:uid/:firm_id',
        name: 'sajhedari-firm-transfer-certificate',
        component: FirmTransferCertificate,
        meta: {
            title: 'Transfer Certificate', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/sajhedari-firms/branch-certificate/:uid/:firm_id',
        name: 'sajhedari-firm-branch-certificate',
        component: FirmBranchCertificate,
        meta: {
            title: 'Branch Certificate', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/sajhedari-firms/branch-certificate/:uid/:firm_id/letter',
        name: 'sajhedari-firm-branch-letter',
        component: FirmBranchLetter,
        meta: {
            title: 'Branch Letter', rule: true,
            requiresAuth: true,
        }
    },

    //PrivateFirm
    {
        path: '/private-firms',
        name: 'private-firm-index',
        component: PrivateFirmIndex,
        meta: {
            title: 'Private Firm', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/private-firms/create',
        name: 'private-firm-create',
        component: PrivateFirmForm,
        meta: {
            title: 'Create Private Firm', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/private-firms/:uid/edit',
        name: 'private-firm-edit',
        component: PrivateFirmForm,
        meta: {
            title: 'Edit Private Firm', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/private-firms/:uid/history',
        name: 'private-firm-history',
        component: PrivateFirmHistory,
        meta: {
            title: 'Private Firm History', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/private-firms/certificate/:uid',
        name: 'private-firm-certificate',
        component: FirmCertificate,
        meta: {
            title: 'Certificate', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/private-firms/register-certificate/:uid',
        name: 'private-firm-letter-certificate',
        component: FirmRegisterCertificate,
        meta: {
            title: 'Registration Letter', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/private-firms/renewal-certificate/:uid/:firm_id',
        name: 'private-firm-renewal-certificate',
        component: FirmRenewalCertificate,
        meta: {
            title: 'Renewal Certificate', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/private-firms/samsodhan-certificate/:uid/:firm_id',
        name: 'private-firm-samsodhan-certificate',
        component: FirmSamsodhanCertificate,
        meta: {
            title: 'Samsodhan Certificate', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/private-firms/khareji-certificate/:uid/:firm_id',
        name: 'private-firm-khareji-certificate',
        component: FirmKharejiCertificate,
        meta: {
            title: 'Khareji Certificate', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/private-firms/transfer-certificate/:uid/:firm_id',
        name: 'private-firm-transfer-certificate',
        component: FirmTransferCertificate,
        meta: {
            title: 'Transfer Certificate', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/private-firms/branch-certificate/:uid/:firm_id',
        name: 'private-firm-branch-certificate',
        component: FirmBranchCertificate,
        meta: {
            title: 'Branch Certificate', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/private-firms/branch-certificate/:uid/:firm_id/letter',
        name: 'private-firm-branch-letter',
        component: FirmBranchLetter,
        meta: {
            title: 'Branch Letter', rule: true,
            requiresAuth: true,
        }
    },

    //Firm Register
    {
        path: '/register-firms',
        name: 'register-firm-index',
        component: RegisterFirmIndex,
        meta: {
            title: 'Firm Registers', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/register-firms/create',
        name: 'register-firm-create',
        component: RegisterFirmForm,
        meta: {
            title: 'Create Firm Register', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/register-firms/:uid/edit',
        name: 'register-firm-edit',
        component: RegisterFirmForm,
        meta: {
            title: 'Edit Firm Register', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/register-firms/:uid/history',
        name: 'register-firm-history',
        component: RegisterFirmHistory,
        meta: {
            title: 'Firm History', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/register-firms/:uid/samsodhan',
        name: 'register-firm-samsodhan',
        component: RegisterFirmSamdodhan,
        meta: {
            title: 'Firm Samsodhan', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/register-firms/:firm_id/adyavadhik',
        name: 'register-firm-adyavadhik',
        component: RegisterFirmAdyavadhik,
        meta: {
            title: 'Firm Adyavadhik', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/register-firms/certificate/:uid',
        name: 'register-firm-certificate',
        component: RegisterFirmCertificate,
        meta: {
            title: 'Certificate', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/register-firms/register-certificate/:uid',
        name: 'register-firm-letter-certificate',
        component: RegisterFirmRegisterCertificate,
        meta: {
            title: 'Registration Letter', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/register-firms/renewal-certificate/:uid/:firm_id',
        name: 'register-firm-renewal-certificate',
        component: FirmRenewalCertificate,
        meta: {
            title: 'Renewal Certificate', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/register-firms/samsodhan-certificate/:uid/:firm_id',
        name: 'register-firm-samsodhan-certificate',
        component: RegisterFirmSamsodhanCertificate,
        meta: {
            title: 'Samsodhan Certificate', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/register-firms/khareji-certificate/:uid/:firm_id',
        name: 'register-firm-khareji-certificate',
        component: FirmKharejiCertificate,
        meta: {
            title: 'Khareji Certificate', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/register-firms/transfer-certificate/:uid/:firm_id',
        name: 'register-firm-transfer-certificate',
        component: FirmTransferCertificate,
        meta: {
            title: 'Transfer Certificate', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/register-firms/branch-certificate/:uid/:firm_id',
        name: 'register-firm-branch-certificate',
        component: FirmBranchCertificate,
        meta: {
            title: 'Branch Certificate', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/register-firms/adyavadhik-certificate/:uid/:firm_id',
        name: 'register-firm-adyavadhik-certificate',
        component: RegisterFirmAdyavadhikCertificate,
        meta: {
            title: 'Adyavadhik Detail', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/register-firms/extension-certificate/:uid/:firm_id',
        name: 'register-firm-extension-certificate',
        component: RegisterFirmDateExtensionCertificate,
        meta: {
            title: 'Date Extension', rule: true,
            requiresAuth: true,
        }
    },

    //Settings
    //Capitals
    {
        path: '/capitals',
        name: 'capital-index',
        component: CapitalIndex,
        meta: {
            title: 'Capitals', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/capitals/create',
        name: 'capital-create',
        component: CapitalForm,
        meta: {
            title: 'Create Capital', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/capitals/:uid/edit',
        name: 'capital-edit',
        component: CapitalForm,
        meta: {
            title: 'Edit Capital', rule: true,
            requiresAuth: true,
        }
    },

    //Purpose
    {
        path: '/purposes',
        name: 'purpose-index',
        component: PurposeIndex,
        meta: {
            title: 'Purposes', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/purposes/create',
        name: 'purpose-create',
        component: PurposeForm,
        meta: {
            title: 'Create Purpose', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/purposes/:uid/edit',
        name: 'purpose-edit',
        component: PurposeForm,
        meta: {
            title: 'Edit Purpose', rule: true,
            requiresAuth: true,
        }
    },

    //Setting Options
    {
        path: '/settings-options',
        name: 'options-index',
        component: OptionIndex,
        meta: {
            title: 'Options', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/settings-options/create',
        name: 'options-create',
        component: OptionForm,
        meta: {
            title: 'Create Option', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/settings-options/:uid/edit',
        name: 'options-edit',
        component: OptionForm,
        meta: {
            title: 'Edit Option', rule: true,
            requiresAuth: true,
        }
    },

    //Firm Report
    {
        path: '/firms/industry-report',
        name: 'report-firm-industry',
        component: ReportFirmIndustry,
        meta: {
            title: 'Firm Industry Report', rule: true,
            requiresAuth: true,
        }
    },
    {
        path: '/firms/commercial-report',
        name: 'report-firm-commercial',
        component: ReportFirmCommercial,
        meta: {
            title: 'Firm Commercial Report', rule: true,
            requiresAuth: true,
        }
    },
];

export default routes
