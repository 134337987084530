import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    fetchReservedName(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/firm-reserve-name`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeReserveFirmName(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/firm-reserve-name`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showReserveFirmName(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/firm-reserve-name`, params)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteReserveFirmName(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/firm-reserve-name/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
