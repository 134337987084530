import axios from "axios";

const state = {}
const mutations = {}
const getters = {}

const actions = {
    getCapitals(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/capital`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createCapital(context) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/capital/create`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeCapital(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/capital`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editCapital(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/capital/${data.uid}/edit`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateCapital(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/capital/${data.uid}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteCapital(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/capital/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
