import axios from "axios";

const state = {}
const mutations = {}
const getters = {}

const actions = {
    getTraineeTrainings(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/trainee-training`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createTraineeTraining(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/trainee-training/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeTraineeTraining(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/trainee-training`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showTraineeTraining(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/trainee-training/${data.slug}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editTraineeTraining(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/trainee-training/${data}/edit`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateTraineeTraining(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/trainee-training/${data.Trainee_training_id}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteTraineeTraining(context, payload) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/trainee-training/${payload.trainee_id}`, {params: payload})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }
}
export default {
    state,
    mutations,
    actions,
    getters
}
