import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    getIndustryRegistrations(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/industry-registration`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createIndustryRegistration(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/industry-registration/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeIndustryRegistration(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/industry-registration`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showIndustryRegistration(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/industry-registration/${params.uid}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editIndustryRegistrationByUid(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/industry-registration/${params.uid}/edit`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateIndustryRegistration(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/industry-registration/${data.uid}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteIndustryRegistration(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/industry-registration/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    getIndustryRegistrationExportView(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/industry-registration-export`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateIndustryRegistrationStatus(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/industry-registration-status/${data.uid}`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    resendForReviewIndustryRegistration(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/industry-registration-resend/${data.uid}/review`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    industryRegistrationReport(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/industry-registration-report`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
