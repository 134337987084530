import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    fetchFirmRevenue(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/firm/revenue/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeFirmRevenue(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/firm/revenue`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }
}
export default {
    state,
    mutations,
    actions,
    getters
}
