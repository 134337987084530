import axios from "axios";

const state = {}
const mutations = {}
const getters = {}
const actions = {
    fetchFirmRegisters(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/firm-register`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    createFirmRegister(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/firm-register/create`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    storeFirmRegister(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/firm-register`, data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    showFirmRegister(context, params) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/firm-register/${params.uid}`, {params: params})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    editFirmRegister(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.get(`/firm-register/${data}/edit`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    updateFirmRegister(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.post(`/firm-register/${data.uid}?_method=PUT`, data.form)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    deleteFirmRegister(context, data) {
        if (context.getters.token) {
            return new Promise((resolve, reject) => {
                axios.delete(`/firm-register/${data}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}
export default {
    state,
    mutations,
    actions,
    getters
}
